<template>
  <Layout>
    <div class="container-fluid pt-4">
      <div class="row">
        <div class="col">
          <h5 class="page-header">New User</h5>
        </div>
      </div>
      <hr class="dotted-spacer" />
      <!-- Loading spinner -->
      <div
        v-if="$apollo.loading"
        class="position-fixed end-0 w-100 justify-content-center"
        style="background: rgba(255, 255, 255, 0.8); height: 100vh; z-index: 1050"
      >
        <div class="text-center col pt-5 mt-5">
          <BaseSpinner />
          <p class="d-block lead fs-4 mt-5">Loading user data</p>
        </div>
      </div>
      <div class="row px-5 pt-4">
        <div class="col col-md-8 col-lg-6 col-xl-4">
          <h5>
            Please assign users through your enterprise identity provider or get in contact with Jadepacific for new
            users.
          </h5>
          <!-- 
          <ValidationObserver ref='form' v-slot="{  }" >
            <form @submit.prevent="createUser">
              <h5>Profile</h5>
              
              <BaseAlert v-if="alert.type" class="mb-4" :type="alert.type">
                <span slot="title">{{ alert.title }}</span>
                <ul v-if="typeof(alert.message) === 'object'" slot="message">
                  <li v-for="m in alert.message" :key="m">{{ m }}</li>
                </ul>
                <span v-else slot="message">{{ alert.message }}</span>
              </BaseAlert>


              <BaseFormGroup :label="'Name'" >
                <ValidationProvider v-slot="{ errors }" name='Name' rules='required' >
                  <BaseInput v-model="editingProfile.name" type="text" name="name" :error="errors[0]" />
                </ValidationProvider>
              </BaseFormGroup>

              <BaseFormGroup :label="'Email'" >
                <ValidationProvider v-slot="{ errors }" name='Email' rules='required|email' >
                  <BaseInput v-model="editingProfile.email" type="text" name="email" :error="errors[0]" />
                </ValidationProvider>
              </BaseFormGroup>

              <BaseFormGroup :label="'Phone'" >
                <ValidationProvider v-slot="{ errors }" name='Phone' rules='required' >
                  <BaseInput v-model="editingProfile.phone" type="text" name="phone" :error="errors[0]" />
                </ValidationProvider>
              </BaseFormGroup>

              <BaseFormGroup :label="'Location'" >
                <ValidationProvider v-slot="{ errors }" name='Location' rules='required'>
                  <BaseMultiselect v-model="editingProfile.location" :options="locations" :error="errors[0]" name="location" />
                </ValidationProvider>
              </BaseFormGroup>

              <BaseFormGroup :label="'Team role'" class="text-primary" >
                <BaseMultiselect v-model="editingProfile.role" :options="availableRoles" :single="true" name="role" track-by="name" />
              </BaseFormGroup>

              <BaseFormGroup v-if="editingProfile.role.name == 'TEAM'">
                <ValidationProvider v-slot="{ errors }" name='Self approve' rules="required" >
                  <BaseInput v-model="editingProfile.selfApprove" type="checkbox" name="self-approve" :error="errors[0]"><span>Can self-approve activities</span></BaseInput>
                </ValidationProvider>
              </BaseFormGroup>

              <BaseFormGroup class=" mt-5 mx-5">
                <BaseButton type="submit" :disabled="isLoading" :classes="'btn-success'" >
                  <span>Save</span>
                </BaseButton>

                <BaseButton class="btn-secondary mx-5 px-4" :disabled="isLoading" type="button" @click="$router.push({name: 'users'})">
                  <span>Back</span>
                </BaseButton>
              </BaseFormGroup>
            </form>
          </ValidationObserver>
          -->
          <BaseButton
            class="btn-secondary mt-5"
            :disabled="isLoading"
            type="button"
            @click="$router.push({ name: 'users' })"
          >
            <span>Back</span>
          </BaseButton>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@layouts/main";
import { LocalGetSelf, CreateUser } from "@gql/user";
// import { GetTags } from "@gql/tag";
// eslint-disable-next-line vue/no-unused-components
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  page() {
    const name = this.editingProfile ? this.editingProfile.name : "Profile";

    return {
      title: name,
      meta: [
        {
          name: "description",
          content: `Create user.`,
        },
      ],
    };
  },
  // eslint-disable-next-line vue/no-unused-components
  components: { Layout, ValidationProvider, ValidationObserver },
  data() {
    return {
      isLoading: false,
      alert: {},
      editingProfile: {
        name: "",
        email: "",
        // phone: "",
        // grade: null,
        // location: null,
        // group: null,
        selfApprove: false,
        role: { name: "USER", label: "User" },
      },
      availableRoles: [
        { name: "ADMIN", label: "Admin" },
        { name: "TEAM", label: "Team member" },
        { name: "VIEWER", label: "Viewer" },
        { name: "USER", label: "User" },
      ],
    };
  },
  apollo: {
    user: {
      query: LocalGetSelf,
      update(data) {
        if (!data.user.isAdminUser) {
          this.$router.push({ name: "403", params: { resource: "user.edit" } });
        }
        return data.user;
      },
    },
    // locations: {
    //   query: GetTags,
    //   variables() {
    //     return {
    //       where: {
    //         company: {
    //           id: { equals: this.user.company.id },
    //         },
    //         type: { name: { equals: "location" } },
    //       },
    //     };
    //   },
    //   skip() {
    //     return !this.user;
    //   },
    //   update(data) {
    //     this.$log.debug("Got Locations from API: ", data);
    //     return data.tags;
    //   },
    // },
  },
  methods: {
    async createUser() {
      this.isLoading = true;
      this.alert = {};

      // Validate the form
      if (!(await this.$refs.form.validate())) {
        this.isLoading = false;
        this.alert.type = "error";
        this.alert.title = "Error";
        this.alert.message = "Please check the form below for errors";
        return false;
      }
      // Validation good, reset
      this.$refs.form.reset();

      const canSelfApprove = ["ADMIN", "SYSADMIN"].includes(this.editingProfile.role.name)
        ? true
        : ["VIEWER"].includes(this.editingProfile.role.name)
        ? false
        : this.editingProfile.selfApprove;

      this.$apollo
        .mutate({
          mutation: CreateUser,
          variables: {
            role: this.editingProfile.role.name,
            selfApprove: canSelfApprove || false,
            name: this.editingProfile.name || undefined,
            // phone: this.editingProfile.phone || null,
            // location: this.editingProfile.location ? this.editingProfile.location.id : null,
          },
          update: (store, { data: { createUser } }) => {},
          error(err) {
            this.$log.error(err);
            this.alert.type = "error";
            this.alert.title = "There was a problem";
            this.alert.message = err;
          },
        })
        .then((data) => {
          this.alert.type = "success";
          this.alert.title = "Success";
          this.alert.message = "User updated";
          this.isLoading = false;
          this.$refs.form.reset();
          // Redirect
          setTimeout(() => {
            this.$router.push({ name: "users" });
          }, 1000);
        })
        .catch((err) => {
          // Error
          this.alert.type = "error";
          this.alert.title = "There was a problem";
          this.alert.message = err;
        });
    },
  },
};
</script>
